import React , {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import SuccessAlert from "../components/SuccessAlert";
import FailedAlert from "../components/FailedAlert";


export default function ChangePassword(){

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailed, setOpenFailed] = useState(false);

    const theme = createTheme();

    const queryString = window.location.search.substring(1);

    const [input, setInput] = useState({
        password: "",
        passwordCheck: ""
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        checkFields();
    };

    function checkFields() {
        if (input.passwordCheck === "" || input.password === ""){
            alert("All required fields must be entered!");
        }
        else if(input.passwordCheck !== input.password){
            alert("Passwords doesn't match!");
        }
        else {
            updatePassword();
            backToHome();
        }
    }

    function backToHome(){
        window.location.assign("../");
    }
    function updatePassword() {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/account/changePassword?newPassword=${input.password}&token=${queryString}`, {
            method: "POST"
        })
            .then(response => response.json())
            .then(value => console.log(value));

    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <SuccessAlert openSuccess={openSuccess} setOpenSuccess={setOpenSuccess}/>
                <FailedAlert openFailed={openFailed} setOpenFailed={setOpenFailed}/>
                <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1>Change Password</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="password" label="Password" onChange={event => textFieldHandler(event)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="passwordCheck" label="Password Check" value={input.passwordUrl} onChange={event => textFieldHandler(event)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" sx={{mt: 3, mb: 2, width:'50%'}}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}