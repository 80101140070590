import React, {useEffect, useState} from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {createRandomPassword} from "./RandomPassword";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import {decrypt, encrypt} from "./PasswordEncryption";

const id = sessionStorage.getItem("passwordId");

export default function EditPassword(){

    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        if(userId !== ""){
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/authenticate/isEnabled?userId=${userId}` ,{
                method: "GET"
            })
                .then(response => response.json())
                .then(value => {
                    if(value !== true){
                        window.location.assign('../account/Login');
                    }
                })
        } else {
            window.location.assign('../account/Login');
        }
    },)

    useEffect(() => {
        getData();
    }, []);

    const theme = createTheme();

    const [input, setInput] = useState({
        passwordName: "",
        passwordUrl: "",
        password: "",
        group: "",
        categories: "",
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        registerHandler();
    };

    function getData(){
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/editPassword?id=${id}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(value => {
                if(value.name != null) {
                    input.passwordName = value.name;
                }
                if(value.url != null) {
                    input.passwordUrl = value.url;
                }
                if(value.password != null) {
                    input.password = decrypt(value.password, value.encryptionKey);;
                }
                if(value.group != null) {
                    input.group = value.group;
                } 
                if(value.category != null) {

                    input.categories = value.category;
                }
                setInput({...input, categories: input.categories});
            })
    }
    function registerHandler() {
        if(input.passwordName != "" || input.password !=""){
            let encryptedPassword = encrypt(input.password);
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/editPassword?name=${input.passwordName}&url=${input.passwordUrl}&password=${encryptedPassword.cipher}&group=${input.group}&category=${input.categories}&id=${id}&encryptionKey=${encryptedPassword.iv}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
                method: "POST"
            })
                .then(value => {
                    sessionStorage.removeItem("passwordId");
                    window.location.assign("/my-passwords");
                });
        }
        else{
            alert("Password or name is empty");
        }

    }

    const RandomizationButton = () => (
        <IconButton onClick={() =>{
            let result = createRandomPassword(12);
            input.password = result;
            setInput({...input, password: input.password})
        }}>
            <CachedOutlinedIcon />
        </IconButton>
    )

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1>Password Editor</h1>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth id="passwordName" label="Password Name" value={input.passwordName} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth id="passwordUrl" label="Password URL" value={input.passwordUrl} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth InputProps={{endAdornment: <RandomizationButton/>}} id="password" label="Password" type="password" value={input.password} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth id="group" label="Group" value={input.group} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth id="categories" label="Categories" value={input.categories} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={6}>
                                <Button onClick={() => window.location.assign("/my-passwords")} variant="contained" id="cancelBtn" sx={{mt: 3, mb: 2, width:'50%'}}>
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button type="submit" variant="contained" id="saveBtn" sx={{mt: 3, mb: 2, width:'50%'}}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
