import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "./ListPassword.module.css";
import {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {TableFooter, TablePagination, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search'
import IconButton from "@mui/material/IconButton";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {decrypt} from "./PasswordEncryption";


const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function PasswordTable() {
    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        if(userId !== ""){
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/authenticate/isEnabled?userId=${userId}` ,{
                method: "GET"
            })
                .then(response => response.json())
                .then(value => {
                    if(value !== true){
                        window.location.assign('../account/Login');
                    }
                })
        } else {
            window.location.assign('../account/Login');
        }
    },)

    const [passwords, setPasswords] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/password-list?token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => {setPasswords(data)});
    }, []);

    function copy(password, iv, id) {

        navigator.clipboard.writeText(decrypt(password, iv));

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/recent-used?id=${id}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "POST"
        });
    }

    function deletePassword(id) {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/password-list?id=${id}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "DELETE"
        })
            .then(() => window.location.reload());
    }

    function handleSort(column) {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/password-list-sort?selectedcolumn=${column}&searched=${searched}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => {setPasswords(data)});
    }



    const Filters = {
        ALL: 1,
        NAME: 2,
        GROUP: 3,
        CATEGORY: 4,
        URL: 5
    };

    const [searchFilter, setSearchFilter] = React.useState(Filters.ALL);

    const handleChange = (e) => {
        setSearchFilter(e.target.value);
    };

    const SortValues = {
        recent: "latest",
        name: "name",
        group: "group",
        category: "category",
        oldest: "oldest"
    };

    const [sort, setSort] = React.useState(SortValues.recent);

    const handleSortChange = (e) => {
        setSort(e.target.value);
        handleSort(e.target.value);
    };

    const [searched, setSearched] = useState("");

    function handleSearch() {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/password-list-filter?searchfilter=${searchFilter}&searchphrase=${searched}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => {setPasswords(data)});

        setPage(0);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer id={styles.container} component={Paper}>
            <Box
                sx={{minWidth: 209, display: "flex", flexDirection: "row", alignItems: "left", justifyContent: "left"}}>
                <FormControl id={styles.filterSelect} size={"small"} margin="dense">
                    <InputLabel>Sort</InputLabel>
                    <Select value={sort} label="sort" onChange={handleSortChange}>
                        <MenuItem value={SortValues.recent}>Recent</MenuItem>
                        <MenuItem value={SortValues.name}>Name</MenuItem>
                        <MenuItem value={SortValues.group}>Group</MenuItem>
                        <MenuItem value={SortValues.category}>Category</MenuItem>
                        <MenuItem value={SortValues.oldest}>Oldest</MenuItem>
                    </Select>
                </FormControl>

                <FormControl id={styles.filterSelect} size={"small"} margin="dense">
                    <InputLabel>
                        Search Filter
                    </InputLabel>

                    <Select value={searchFilter} label="search-filter" onChange={handleChange}>
                        <MenuItem value={Filters.ALL}>All</MenuItem>
                        <MenuItem value={Filters.NAME}>Name</MenuItem>
                        <MenuItem value={Filters.GROUP}>Group</MenuItem>
                        <MenuItem value={Filters.CATEGORY}>Category</MenuItem>
                        <MenuItem value={Filters.URL}>URL</MenuItem>
                    </Select>
                </FormControl>
                <TextField id={styles.searchbar}
                           size={"small"}
                           label="Search Password"
                           variant="outlined"
                           margin="dense"
                           value={searched}
                           //onChange={function(e){setSearched(e.target.value); handleSearch()}}        //THIS IS RESPONSIVE SEARCH, CALLS DB EVERY CHANGE
                           onChange={(e) => setSearched(e.target.value)} //ONLY CALLS DB WHEN PRESS OF BUTTON
                           onKeyDown={handleKeyDown}
                />
                <IconButton id={styles.searchbutton} size="small" onClick={() => handleSearch()}>
                    <SearchIcon/>
                </IconButton>
            </Box>

            <Table sx={{minWidth: 700}} aria-label="password-table">
                <TableHead>
                    <TableRow>
                        <TableCell id={styles.nameCell} onClick={() => {{handleSort("name"); setSort("name");}}}>
                            Name
                        </TableCell>

                        <TableCell id={styles.tableCell} onClick={() => {{handleSort("group"); setSort("group");}}}>
                            Group
                        </TableCell>

                        <TableCell id={styles.tableCell} onClick={() => {{handleSort("category"); setSort("category");}}}>
                            Category
                        </TableCell>

                        <TableCell id={styles.tableCell}>
                            Url
                        </TableCell>

                        <TableCell id={styles.tableCell}>
                            Copy
                        </TableCell>

                        <TableCell id={styles.tableCell}>
                            Delete
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {(rowsPerPage > 0 ? Array.from(passwords).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : passwords
                    ).map((password) => (
                        <StyledTableRow key={password.id}>
                            <StyledTableCell component="th" scope="row" onClick={() => {
                                sessionStorage.setItem("passwordId", password.id);
                                window.location.assign("/password/EditPassword");
                            }}>
                                {password.name}
                            </StyledTableCell>

                            <StyledTableCell align="right" onClick={() => {
                                sessionStorage.setItem("passwordId", password.id);
                                window.location.assign("/password/EditPassword");
                            }}>
                                {password.group}
                            </StyledTableCell>

                            <StyledTableCell align="right" onClick={() => {
                                sessionStorage.setItem("passwordId", password.id);
                                window.location.assign("/password/EditPassword");
                            }}>
                                {password.category}
                            </StyledTableCell>

                            <StyledTableCell align="right" onClick={() => {
                                sessionStorage.setItem("passwordId", password.id);
                                window.location.assign("/password/EditPassword");
                            }}>
                                {password.url}
                            </StyledTableCell>

                            <StyledTableCell align="right">
                                <button onClick={() => copy(password.password, password.encryptionKey, password.id)}>
                                    <ContentCopyIcon/>
                                </button>
                            </StyledTableCell>

                            <StyledTableCell align="right">
                                <button onClick={() => deletePassword(password.id)}>
                                    <DeleteIcon/>
                                </button>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TablePagination
                            style={{paddingRight: '34%'}}
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            count={passwords.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{inputProps: {'aria-label': 'rows per page'} , native: true}}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
