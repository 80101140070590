import React from 'react';

const isLoggedIn = () => {
    return sessionStorage.getItem("userId") === "";
}

const Home = () => {
    return (
        <div>
            <h1 style={{display: (isLoggedIn() ? 'block' : 'none')}}>Please log in to continue</h1>
            <h1 style={{display: (isLoggedIn() ? 'none' : 'block')}}>Welcome!</h1>
        </div>
    );
};

export default Home;