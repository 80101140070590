import React , {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog(props) {

    const handleClose = () => {
        props.setOpenGroup(false);
    };

    const [input, setInput] = useState({
        name: ""
    });



    function handleSubmit() {
        if (input.name === "")
            alert("All required fields must be entered!")
        else {
            createGroupHandler();
        }
    }
    function changeToEditGroup(groupId){
        //sessionStorage.setItem("groupId", groupId); //TODO fixa så detta kanske är säkrare?
        //window.location.assign("/password/EditPassword");
    }

    function createGroupHandler() { //TODO fixa create i backend
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/group/creategroup?groupName=${input.name}`, {
            method: "POST"
        })
            .then(response => response.json())
            .then(value => {
                if(value.id !== null){
                    changeToEditGroup(value.id);
                }
            })
    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            createGroupHandler();
        }
    }

    return (
        <div>
            <Dialog open={props.openGroup}  onClose={() => handleClose()}>
                <DialogTitle>Add Group</DialogTitle>
                <DialogContent>
                    <TextField
                        required={true}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Group Name"
                        type="name"
                        fullWidth
                        variant="standard"
                        onChange={event => textFieldHandler(event)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} type="close">
                        Cancel
                    </Button>

                    <Button variant="outlined" type="submit" onClick={handleSubmit}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
