import React, {useState} from 'react';
import Header from "./components/Header";
import LeftMenu from "./components/LeftMenu";
import Footer from "./components/Footer";
import Registration from "./account/Registration";
import Home from "./home/Home";
import CreatePassword from "./password/CreatePassword"
import CreateGroup from "./group/CreateGroup"
import EditPassword from "./password/EditPassword"
import ListPassword from "./password/ListPassword"
import Login from "./account/Login"
import ChangePassword from  "./account/changePassword"
import ConfirmPassword from "./account/ConfrimPassword"
import ForgotPassword from "./account/ForgotPassword";
import ListGroup from "./group/ListGroup";
import {BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import EditGroup from "./group/EditGroup";
import JoinGroup from "./group/JoinGroup";


if (!sessionStorage.getItem("userId")) {
    sessionStorage.setItem("userId", "")
}

if (!sessionStorage.getItem("token")) {
    sessionStorage.setItem("token", "")
}

const Root = () => {
    const [openSideMenu, setOpenSideMenu] = useState(false);
    const [openPassword, setOpenPassword] = React.useState(false);
    const [openGroup, setOpenGroup] = React.useState(false);
    const [openJoinGroup, setOpenJoinGroup] = React.useState(false);



    const setUserId = (userId) => {
        sessionStorage.setItem("userId", userId)
    }

    const setToken = (token) => {
        sessionStorage.setItem("token", token)
    }

    return (

        <div>
            <div style={{marginBottom: '10vh'}}>
                <Header onClick={() => {
                    if (sessionStorage.getItem("passwordId")) {
                        sessionStorage.removeItem("passwordId");
                    }
                    setOpenSideMenu(openSideMenu => !openSideMenu);
                }}/>

                <LeftMenu openSideMenu={openSideMenu} setOpenSideMenu={setOpenSideMenu} onClick={(value) => {{

                    if(value === "Group"){
                        setOpenGroup(openGroup => !openGroup);
                    }
                    if(value === "Password"){
                        setOpenPassword(openPassword => !openPassword);
                    }
                    if(value === "JoinGroup"){
                        setOpenJoinGroup(openJoinGroup => !openJoinGroup);
                    }
                }}}/>

                <CreatePassword openPassword={openPassword} setOpenPassword={setOpenPassword}/>

                <CreateGroup openGroup={openGroup} setOpenGroup={setOpenGroup}/>

                <JoinGroup openJoinGroup={openJoinGroup} setOpenJoinGroup={setOpenJoinGroup}/>

                <Router>
                    <Routes>
                        <Route path='/account/Login' exact element={<Login setUserId={setUserId} setToken={setToken}/>}/>
                        <Route path='/account/forgot-password' exact element={<ForgotPassword/>} />
                        <Route path='/account/Registration' exact element={<Registration/>}/>
                        <Route path='/account/confirm' exact element={<ConfirmPassword/>} />
                        <Route path='/password/EditPassword' exact element={<EditPassword/>}/>
                        <Route path='/my-passwords' exact element={<ListPassword/>}/>
                        <Route path='/account/changePassword' exact element={<ChangePassword/>} />
                        <Route path='/my-groups' exact element={<ListGroup/>}/>
                        <Route exact path='/' element={<Home/>}/>
                        <Route path='/dashboard/:userId/' element={<Home/>}/>
                        <Route path='/group/EditGroup' exact element={<EditGroup/>} />
                    </Routes>
                </Router>
            </div>

            <Footer/>
        </div>
    );
};

export default Root;