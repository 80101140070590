import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PasswordIcon from '@mui/icons-material/Password';
import KeyIcon from '@mui/icons-material/Key';
import GroupsIcon from '@mui/icons-material/Groups';


export default function TemporaryDrawer(props) {

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    function verifyLegalUser(func){
        const userId = sessionStorage.getItem("userId");
        if(userId !== ""){

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/authenticate/isEnabled?userId=${userId}` ,{
                method: "GET"
            })
                .then(response => response.json())
                .then(value => {
                    if(value === true){
                        func();
                    } else {
                        alert("You have to verify your account via email to view this page!")
                    }
                })
        } else {
            alert("You have to be logged in to view this page!")
        }
    }

    const toMyPasswords = () => {
        window.location.href="../my-passwords";
    }

    const toCreatePassword = () => {
        props.onClick("Password"); //TODO kolla om det går att fixa erroret som kommer i console
    }

    const toCreateGroup = () => {
        props.onClick("Group");
    }
    const toMyGroups = () => {
        window.location.href="../my-groups";
    }
    const toJoinGroup = () => {
        props.onClick("JoinGroup");
    }

    const list = () => (
        <Box
            sx={{width: "left" === 'top' || "left" === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer("left", false)}
            onKeyDown={toggleDrawer("left", false)}
        >
            <List>
                <ListItem button key={"MyPasswords"} onClick={() => verifyLegalUser(toMyPasswords)}>
                    <ListItemIcon>
                        <KeyIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"My Passwords"}/>
                </ListItem>

                <ListItem button onClick={() => verifyLegalUser(toCreatePassword)}  key={"CreatePassword"}>
                    <ListItemIcon>
                        <PasswordIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Create Password"}/>
                </ListItem>

                <Divider/>

                <ListItem button key={"myGroups"} onClick={() => verifyLegalUser(toMyGroups)}>
                    <ListItemIcon>
                        <GroupsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"My Groups"}/>
                </ListItem>
                <ListItem button onClick={() => verifyLegalUser(toCreateGroup)} key={"CreateGroup"}>
                    <ListItemIcon>
                        <GroupsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Create Group"}/>
                </ListItem>

                <ListItem button onClick={() => verifyLegalUser(toJoinGroup)} key={"JoinGroup"}>
                    <ListItemIcon>
                        <GroupsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Join Group"}/>
                </ListItem>

                <ListItem button key={"EditGroup"} onClick={() => window.location.href="../group/EditGroup"}>
                    <ListItemIcon>
                        <GroupsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Edit Group"}/>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment>
                <Drawer anchor={"left"} open={props.openSideMenu} onClose={() => props.setOpenSideMenu(false)}>
                    {list("left")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
