
export function createRandomPassword(length) {

    let result = '';
    let upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    let numbers = '0123456789';
    let specialChar = '!/()=?-.,@';
    let characters = upperCase+lowerCase+numbers+specialChar;
    let charactersLength = characters.length;
    let missingChar = [0,0,0,0];

    for ( let i = 0; i < length; i++ ) {
        let num = Math.floor(Math.random() * charactersLength);

        result += characters.charAt(num);

        if(num <= 25){
            missingChar[0]++;
        }
        if(num > 25 && num <= 51){
            missingChar[1]++;
        }
        if(num > 51 && num <= 61 ){
            missingChar[2]++;
        }
        if(num > 58 ){
            missingChar[3]++;
        }
    }

    if(missingChar[0] === 0){
        result += characters.charAt(Math.floor(Math.random() * 25));
    }
    if(missingChar[1] === 0){
        result += characters.charAt(Math.floor(Math.random() * 26) + 26);
    }
    if(missingChar[2] === 0){
        result += characters.charAt(Math.floor(Math.random() * 10) + 52);
    }
    if(missingChar[3] === 0){
        result += characters.charAt(Math.floor(Math.random() * 13) + 62);
    }

    return result;
}
