import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';


export default function FailedAlerts(props, message) {
    return (
        <Box sx={{ width: '100%', marginTop:'3vh'}}>
            <Collapse in={props.openFailed}>
                <Alert severity="error" sx={{ mb: 2 }}
                       action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={() => {props.setOpenFailed(false);}}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    <AlertTitle>
                        Failed!
                    </AlertTitle>
                </Alert>
            </Collapse>
        </Box>
    );
}