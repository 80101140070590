import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { createRandomPassword } from "./RandomPassword";
import {encrypt} from "./PasswordEncryption";

export default function FormDialog(props) {

    const handleClose = () => {
        props.setOpenPassword(false);
    };

    const [input, setInput] = useState({
        passwordName: "",
        password: ""
    });

     function handleSubmit() {
        if (input.passwordName === "" || input.password === "")
            alert("All required fields must be entered!")
        else {
            createPasswordHandler();
        }
    }
    function changeToEditPassword(id){
         sessionStorage.setItem("passwordId", id);
         window.location.assign("/password/EditPassword");
    }

    function createPasswordHandler() {
         const encryptedPassword = encrypt(input.password);

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/password/createpassword?passwordName=${input.passwordName}&password=${encryptedPassword.cipher}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}&passwordEncryptionKey=${encryptedPassword.iv}`, {
            method: "POST"
        })
            .then(response => response.json())
            .then(value => {
                if(value.id != null){
                    changeToEditPassword(value.id);
                }
            })
    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            createPasswordHandler();
        }
    }
    const RandomizationButton = () => (
        <IconButton onClick={() =>{
            let result = createRandomPassword(12);
            input.password = result;
            setInput({...input, password: input.password})
        }}>
            <CachedOutlinedIcon />
        </IconButton>
    );

    const {encrypt, decrypt} = require('./PasswordEncryption');

    return (
        <div>
            <Dialog open={props.openPassword}  onClose={() => handleClose()}>
                <DialogTitle>Add Password</DialogTitle>
                <DialogContent>
                    <TextField
                        required={true}
                        autoFocus
                        margin="dense"
                        id="passwordName"
                        label="Password Name"
                        type="name"
                        fullWidth
                        variant="standard"
                        onChange={event => textFieldHandler(event)}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={event => textFieldHandler(event)}
                        onKeyDown={handleKeyDown}
                        InputProps={{endAdornment: <RandomizationButton/>}}
                        value={input.password}
                    />

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} type="close">
                        Cancel
                    </Button>

                    <Button variant="outlined" type="submit" onClick={handleSubmit}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
