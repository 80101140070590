import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ValidateEmail from './EmailValidation';
import IconButton from "@mui/material/IconButton";
import {createRandomPassword} from "../password/RandomPassword";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import SuccessAlert from "../components/SuccessAlert";
import FailedAlert from "../components/FailedAlert";



const theme = createTheme();

export default function Registration() {
    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        if(userId !== ""){
            window.location.replace("../");
        }
    },)

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailed, setOpenFailed] = useState(false);


    const [input, setInput] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if(ValidateEmail(data.get('email')) && input.firstName !== "" && input.lastName !== "" && input.email !== "" && input.password !== "") {
            registerHandler();
            window.location.replace("./Login");
        }
        else{
            alert("All required fields must be entered!")
        }
    };

    function registerHandler() {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/account/registration?firstName=${input.firstName}&lastName=${input.lastName}&email=${input.email}&password=${input.password}`, {
            method: "POST"
        })
            .then(response => {
                response.json();

                if (response.status === 200){
                    setOpenFailed(false);
                    setOpenSuccess(true);
                }
                else{
                    setOpenSuccess(false);
                    setOpenFailed(true);
                }
            })

    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

    const RandomizationButton = () => (
        <IconButton onClick={() =>{
            let result = createRandomPassword(12);
            input.password = result;
            setInput({...input, password: input.password})
        }}>
            <CachedOutlinedIcon />
        </IconButton>
    )

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <SuccessAlert openSuccess={openSuccess} setOpenSuccess={setOpenSuccess}/>
                <FailedAlert openFailed={openFailed} setOpenFailed={setOpenFailed}/>
                <CssBaseline/>
                <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    aria-label={"firstName"}
                                    autoFocus
                                    onChange={event => textFieldHandler(event)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    aria-label={"lastName"}
                                    autoComplete="family-name"
                                    onChange={event => textFieldHandler(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    aria-label={"email"}
                                    autoComplete="email"
                                    onChange={event => textFieldHandler(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    aria-label={"password"}
                                    autoComplete="new-password"
                                    InputProps={{endAdornment: <RandomizationButton/>}}
                                    value={input.password}
                                    onChange={event => textFieldHandler(event)}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="../account/Login" variant="body2">
                                    Already have an account? Log in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}