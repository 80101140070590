import {createRandomPassword} from "./RandomPassword";

const crypto = require('crypto-js');


export const encrypt = (password) => {
    let secret = createRandomPassword(18);
    var ciphertext = crypto.AES.encrypt(password, secret).toString();
    return {iv: secret.toString(), cipher: ciphertext};
};


export const decrypt = (encryption, iv) => {
    var bytes = crypto.AES.decrypt(encryption.replaceAll(' ', '+'), iv);
    var decryptedData = bytes.toString(crypto.enc.Utf8);
    return decryptedData;
};
