import React , {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog(props) {

    const handleClose = () => {
        props.setOpenJoinGroup(false);
    };

    const [input, setInput] = useState({
        groupCode: ""
    });



    function handleSubmit() {
        if (input.groupCode === "")
            alert("All required fields must be entered!")
        else {
            joinGroupHandler();
        }
    }
    function changeToEditGroup(){
        window.location.assign("../");
    }

    function joinGroupHandler() {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/groups/joinGroup?groupCode=${input.groupCode}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "POST"
        })
            .then(response => {
                response.json();

                if (response.status === 200){
                    changeToEditGroup();
                }
                else{
                    alert("Something went wrong");
                }
            })
    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            joinGroupHandler();
        }
    }

    return (
        <div>
            <Dialog open={props.openJoinGroup}  onClose={() => handleClose()}>
                <DialogTitle>Add Group</DialogTitle>
                <DialogContent>
                    <TextField
                        required={true}
                        autoFocus
                        margin="dense"
                        id="groupCode"
                        label="Group Code"
                        type="name"
                        fullWidth
                        variant="standard"
                        onChange={event => textFieldHandler(event)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} type="close">
                        Cancel
                    </Button>

                    <Button variant="outlined" type="submit" onClick={handleSubmit}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
