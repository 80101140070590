import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../password/ListPassword.module.css";
import {useEffect, useState} from "react";
import {TableFooter, TablePagination} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function GroupTable() {

    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        if (userId !== "") {
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/authenticate/isEnabled?userId=${userId}`, {
                method: "GET"
            })
                .then(response => response.json())
                .then(value => {
                    if (value !== true) {
                        window.location.assign('../account/Login');
                    }
                })
        } else {
            window.location.assign('../account/Login');
        }
    },)

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/groups/list?token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => {setGroups(data); console.log(data)});
    }, []);


    // const [searched, setSearched] = useState("");
    //
    // function handleSearch() {
    //     fetch(`http://localhost:8080/password/password-list-filter?searchfilter=${searchFilter}&searchphrase=${searched}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
    //         method: "GET"
    //     })
    //         .then(response => response.json())
    //         .then(data => {setGroups(data)});
    //
    //     setPage(0);
    // }
    //
    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         handleSearch();
    //     }
    // }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer id={styles.container} component={Paper}>
            {/*<Box*/}
            {/*    sx={{minWidth: 209, display: "flex", flexDirection: "row", alignItems: "left", justifyContent: "left"}}>*/}
            {/*    <FormControl id={styles.filterSelect} size={"small"} margin="dense">*/}
            {/*        <InputLabel>Sort</InputLabel>*/}
            {/*        <Select value={sort} label="sort" onChange={handleSortChange}>*/}
            {/*            <MenuItem value={SortValues.recent}>Recent</MenuItem>*/}
            {/*            <MenuItem value={SortValues.name}>Name</MenuItem>*/}
            {/*            <MenuItem value={SortValues.group}>Group</MenuItem>*/}
            {/*            <MenuItem value={SortValues.category}>Category</MenuItem>*/}
            {/*            <MenuItem value={SortValues.oldest}>Oldest</MenuItem>*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}

            {/*    <FormControl id={styles.filterSelect} size={"small"} margin="dense">*/}
            {/*        <InputLabel>*/}
            {/*            Search Filter*/}
            {/*        </InputLabel>*/}

            {/*        <Select value={searchFilter} label="search-filter" onChange={handleChange}>*/}
            {/*            <MenuItem value={Filters.ALL}>All</MenuItem>*/}
            {/*            <MenuItem value={Filters.NAME}>Name</MenuItem>*/}
            {/*            <MenuItem value={Filters.GROUP}>Group</MenuItem>*/}
            {/*            <MenuItem value={Filters.CATEGORY}>Category</MenuItem>*/}
            {/*            <MenuItem value={Filters.URL}>URL</MenuItem>*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*    <TextField id={styles.searchbar}*/}
            {/*               size={"small"}*/}
            {/*               label="Search Password"*/}
            {/*               variant="outlined"*/}
            {/*               margin="dense"*/}
            {/*               value={searched}*/}
            {/*        //onChange={function(e){setSearched(e.target.value); handleSearch()}}        //THIS IS RESPONSIVE SEARCH, CALLS DB EVERY CHANGE*/}
            {/*               onChange={(e) => setSearched(e.target.value)} //ONLY CALLS DB WHEN PRESS OF BUTTON*/}
            {/*               onKeyDown={handleKeyDown}*/}
            {/*    />*/}
            {/*    <IconButton id={styles.searchbutton} size="small" onClick={() => handleSearch()}>*/}
            {/*        <SearchIcon/>*/}
            {/*    </IconButton>*/}
            {/*</Box>*/}

            <Table sx={{minWidth: 700}} aria-label="group-table">
                <TableHead>
                    <TableRow>
                        <TableCell id={styles.nameCell}>
                            Name
                        </TableCell>

                        <TableCell id={styles.tableCell}>
                            Category
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {(rowsPerPage > 0 ? Array.from(groups).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : groups
                    ).map((group) => (
                        <StyledTableRow key={group.id}>
                            <StyledTableCell component="th" scope="row">
                                {group.name}
                            </StyledTableCell>

                            <StyledTableCell align="right">
                                {group.category}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>

                <TableFooter>
                    <TableRow>
                            <TablePagination
                                style={{paddingRight: '34%'}}
                                rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                                //colSpan={0}
                                count={groups.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{inputProps: {'aria-label': 'rows per page'}, native: true}}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
        ;
}
