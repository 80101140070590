import React from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {useEffect, useState} from "react";
import {ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import "./EditGroup.css";


export default function EditPassword(){
    let groupId = sessionStorage.getItem("groupId");

    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        if(userId !== ""){
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/authenticate/isEnabled?userId=${userId}` ,{
                method: "GET"
            })
                .then(response => response.json())
                .then(value => {
                    if(value !== true){
                        window.location.assign('../account/Login');
                    }
                })
        } else {
            window.location.assign('../account/Login');
        }
    },)

    useEffect(() => {
        //getData();
    }, []);

    const theme = createTheme();

    const [input, setInput] = useState({
        groupName: "",
        description: "",
        category: "",
        listOfMembers: "",
        listOfPasswords: "",
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        registerHandler();
    };

    function getData(){
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/group/editGroup?groupId=${groupId}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
            method: "GET"
        })
            .then(response => response.json())
            .then(value => {
                if(value.name != null) {
                    input.groupName = value.name;
                }
                if(value.description != null) {
                    input.description = value.description;
                }
                if(value.category != null) {
                    input.category = value.category;
                }
                if(value.members != null) {
                    input.listOfMembers = value.members;
                }
                if(value.passwords != null) {
                    input.listOfPasswords = value.passwords;
                }
                setInput({...input, groupName: input.groupName});
            })
    }
    function registerHandler() {
        if(input.groupName !== ""){
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/group/editGroup?groupName=${input.groupName}&description=${input.description}&category=${input.category}&members=${input.listOfMembers}&passwords=${input.listOfPasswords}&groupId=${groupId}&token=${sessionStorage.getItem("token").toString()}&userId=${sessionStorage.getItem("userId")}`, {
                method: "POST"
            })
                .then(value => {
                    sessionStorage.removeItem("passwordId");
                    window.location.assign("/my-passwords");
                });
        }
        else{
            alert("Group name is empty");
        }

    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

        const newList = ["test", "funkar", "kanske"];

    function membersList(element, list) {
        return newList.map((value) => (
            <ListItem key={value} disableGutters class={"ListItem"}>
                <ListItemText primary={`${value}`} alignItems="flex-start"/>
            </ListItem>
        ));
    }

    function passwordsList(element, list) {
        return newList.map((value) => (
            <ListItem key={value} disableGutters={true} divider={true}  alignItems="flex-start">
                <ListItemText primary={`${value}`} alignItems="flex-start"/>
            </ListItem>
        ));
    }

    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1>Group Editor</h1>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth id="groupName" label="Group Name" value={input.groupName} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth id="description" label="Description" value={input.description} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth id="category" label="Category"  value={input.category} onChange={event => textFieldHandler(event)}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div" class={"Typography"}>
                                    List of members
                                </Typography>
                                <Demo>
                                    <List class={"List"}>
                                        {membersList()}
                                    </List>
                                </Demo>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div">
                                    List of Passwords
                                </Typography>
                                <Demo>
                                    <List>
                                        {passwordsList()}
                                    </List>
                                </Demo>
                            </Grid>

                            <Grid item xs={6}>
                                <Button onClick={() => window.location.assign("/my-passwords")} variant="contained" id="cancelBtn" sx={{mt: 3, mb: 2, width:'50%'}}>
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button type="submit" variant="contained" id="saveBtn" sx={{mt: 3, mb: 2, width:'50%'}}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
