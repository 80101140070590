import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ValidateEmail from "./EmailValidation";
import SuccessAlert from "../components/SuccessAlert";
import FailedAlert from "../components/FailedAlert";

const theme = createTheme();

export default function ForgotPassword() {
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailed, setOpenFailed] = useState(false);

    const [input, setInput] = useState({
        email: "",
    });


    const handleSubmit = (event) => {
        event.preventDefault();

        if(ValidateEmail(input.email)){
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/account/forgot-password?email=${input.email}`,{
                method: "POST"
            })
                .then(response => {
                    response.json();
                    if (response.status === 200){
                        setOpenFailed(false);
                        setOpenSuccess(true);
                    }
                    else{
                        setOpenSuccess(false);
                        setOpenFailed(true);
                    }
                })
        }
        else {
            alert("All required field must be entered!")
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <SuccessAlert openSuccess={openSuccess} setOpenSuccess={setOpenSuccess}/>
                <FailedAlert openFailed={openFailed} setOpenFailed={setOpenFailed}/>
                <CssBaseline/>
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <EmailIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <Typography component="h6" variant="h6">
                        Enter your email address:
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    aria-label={"email"}
                                    autoComplete="email"
                                    onChange={event => textFieldHandler(event)}
                                    onKeyDown={handleKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                            Forgot password
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}