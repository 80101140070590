import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Link from "@mui/material/Link";

export default function Header(props) {

    const isLoggedIn = () => {
        return sessionStorage.getItem("userId") === "";
    }

    const handleLogout = () => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout/remove-token?userId=${sessionStorage.getItem("userId")}`, {
            method: "DELETE"
        }).then(r => r.json())
        if (sessionStorage.getItem("passwordId")) {
            sessionStorage.removeItem("passwordId");
        }
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("token");
        window.location.assign('../account/Login');
    }

    return (
        <Box sx={{ flexGrow: 1}}>
            <AppBar position="static" sx={{backgroundColor: "#13579b"}}>
                <Toolbar>
                    <IconButton onClick={() => props.onClick()} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                        <MenuIcon/>
                    </IconButton>

                    <Typography component={"div"} to="../" variant="h6" sx={{ flexGrow: 1 }}>
                        <Link href="../" onClick={() => {
                            if (sessionStorage.getItem("passwordId")) {
                                sessionStorage.removeItem("passwordId");
                            }
                        }} style={{textDecoration: 'none', color: 'white'}}>
                            Password Handler
                        </Link>
                    </Typography>
                    <Button style={{display: (isLoggedIn() ? 'block' : 'none')}} href='../account/Login' color="inherit">Login</Button>
                    <Button style={{display: (isLoggedIn() ? 'none' : 'block')}} onClick={handleLogout} color="inherit">Logout</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
