import React from 'react';

let confirmNumber = 0;

export default function ConfirmPassword() {
    const queryString = window.location.search;

    const token = queryString.split('=');

    if(token !== ""){
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/account/confirm?token=${token[1]}`,{
            method: "GET"
        })
            .then(response => { //TODO kolla varför man får två response
                response.json();
                if(response.status === 200){
                    alert("Email is CONFIRMED");
                    confirmNumber++;
                }
                else if (confirmNumber === 0){
                    alert("Email is NOT confirmed");
                    }
            });
    }
    else{
        alert("Email is NOT confirmed");
    }

    return (
        <div>
        </div>
    );

}
