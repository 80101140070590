import React, {useContext, useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ValidateEmail from "./EmailValidation";
import SuccessAlert from "../components/SuccessAlert";
import FailedAlert from "../components/FailedAlert";

const theme = createTheme();

export default function Login({setUserId, setToken}) {

    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        if (userId !== "") {
            window.location.replace("../");
        }
    },)

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailed, setOpenFailed] = useState(false);

    const [input, setInput] = useState({
        email: "",
        password: ""
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        if (ValidateEmail(input.email) && input.password !== "") {
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/authenticate/login?email=${input.email}&password=${input.password}`, {
                method: "GET",
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error("Wrong email or password!")
                })
                .then(value => {
                    setUserId(value.userId);
                    setToken(value.id);
                    window.location.assign("/")
                })
                .catch((error) => alert(error));
        } else {
            alert("All required field must be entered!")
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    const textFieldHandler = event => {
        setInput({...input, [event.target.id]: event.target.value})
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <SuccessAlert openSuccess={openSuccess} setOpenSuccess={setOpenSuccess}/>
                {/*<FailedAlert openFailed={openFailed} setOpenFailed={setOpenFailed}/>*/}
                <CssBaseline/>
                <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Log in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    aria-label={"email"}
                                    autoComplete="email"
                                    onChange={event => textFieldHandler(event)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    aria-label={"password"}
                                    autoComplete="new-password"
                                    onChange={event => textFieldHandler(event)}
                                    onKeyDown={handleKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                            Log in
                        </Button>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Link href="../account/forgot-password" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="../account/Registration" variant="body2">
                                    Don't have an account? Sign up
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}