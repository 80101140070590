import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

export default function TransitionAlerts(props) {

    return (
        <Box sx={{ width: '100%', marginTop:'3vh'}}>
            <Collapse in={props.openSuccess}>
                <Alert sx={{ mb: 2 }}
                    action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={() => {props.setOpenSuccess(false);}}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    Success!
                </Alert>
            </Collapse>
        </Box>
    );
}